import React from 'react'
import Highlight, { Prism, defaultProps } from 'prism-react-renderer';
import dracula from 'prism-react-renderer/themes/dracula';
import styled from "@emotion/styled";

export const Pre = styled.pre`
  text-align: left;
  margin: 1em 0;
  padding: 0.5em;
  overflow: auto;

  & .token-line {
    line-height: 1.3em;
    height: 1.3em;
  }
`;

const CodeBlock = ({ children, className }) => {
  const language = className.replace(/language-/, '') || ""

  return (
    <Highlight {...defaultProps}
      code={children}
      language={language}
      theme={dracula}
      Prism={Prism}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <Pre className={className} style={style }>
          {tokens.map((line, index) => {
            const lineProps = getLineProps({ line, key: index })

            if (line.length === 1 && line[0].content === "\n" && index===tokens.length-1){
              return (
                <></>
              )
            }
            return (
              <div key={index} {...lineProps}>
                {line.map((token, key) => (
                  <span key={key}{...getTokenProps({ token, key })} />
                ))}
              </div>
            )
          }
          )}
        </Pre>
      )}
    </Highlight>
  )
}

export default CodeBlock;