import * as React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Seo from "../components/seo"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import CodeBlock from "../components/CodeBlock"
import styled from "@emotion/styled"

const Container = styled.div`
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
`

const Content = styled.article``;

const BlogPostTemplate = ({ data }) => {
  const post = data.mdx

  return (
    <>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Container>
        <Content
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h1>{post.frontmatter.title}</h1>
            <p>{post.frontmatter.date}</p>
          </header>
          <MDXProvider components={{
            code: CodeBlock,
          }}>
            <MDXRenderer>{post.body}</MDXRenderer>
          </MDXProvider>
          <hr />
          <footer>
            <Bio />
          </footer>
        </Content>
      </Container>
    </>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
