/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"

const Avatar = styled.div`
  float: left;
  margin: 10px;
`;

const Container = styled.div`
  display: block;
  min-height: 70px;
`
const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
        }
      }
    }
  `)

  const author = data.site.siteMetadata?.author
  
  return (
    <Container>
      <Avatar>
        <StaticImage
          className="bio-avatar"
          layout="fixed"
          formats={["auto", "webp", "avif"]}
          src="../images/profile-pic.png"
          width={50}
          height={50}
          quality={95}
          imgStyle={{ borderRadius: '100%' }}
          alt="Profile picture"
        />
      </Avatar>
      Written by <strong>{author.name}</strong> a French computer science student passionate about Machine Learning and Kubernetes
    </Container>
  )
}

export default Bio
